<template>
  <div>
    <b-menu>
      <b-menu-list>
        <b-menu-item
          v-if="$store.state.user && $store.state.user.superuser"
          icon="cog"
          :active="openMenu == 'system'"
          :expanded="openMenu == 'system'"
        >
          <template slot="label" slot-scope="props">
            System
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item icon="domain" label="Districts / Trusts" tag="router-link" to="/districts" :active="this.$route.path == '/districts'" />
          <b-menu-item icon="school" label="Organisations" tag="router-link" to="/subscriptions" :active="this.$route.path == '/subscriptions'" />
          <b-menu-item icon="clock" label="Trials" tag="router-link" to="/trials" :active="this.$route.path == '/trials'" />

          <b-menu-item icon="account-circle" label="Users" tag="router-link" to="/users" :active="this.$route.path == '/users'" />
          <b-menu-item icon="book" label="Dictionary" tag="router-link" to="/dictionary" :active="this.$route.path == '/dictionary'" />
          <b-menu-item icon="alphabetical-variant" label="Letter Tiles Words" tag="router-link" to="/letter_tiles_words" :active="this.$route.path == '/letter_tiles_words'" />
          <b-menu-item icon="alphabetical" label="Phonics Screening" tag="router-link" to="/phonics_screening" :active="this.$route.path == '/phonics_screening'" />
          <b-menu-item icon="microphone" label="Phonemes" tag="router-link" to="/phonemes" :active="this.$route.path == '/phonemes'" />
          <b-menu-item icon="email" label="Emails" tag="router-link" to="/emails" :active="this.$route.path == '/emails'" />
          <b-menu-item icon="download" label="Resources" tag="router-link" to="/resources" :active="this.$route.path == '/resources'" />
          <b-menu-item icon="package" label="External Curricula" tag="router-link" to="/external_curricula" :active="this.$route.path == '/external_curricula'" />
          <b-menu-item icon="package" label="Curriculum Packs" tag="router-link" to="/packs" :active="this.$route.path == '/packs'" />
          <b-menu-item icon="notebook-plus-outline" label="Subjects" tag="router-link" to="/quiz_subjects" :active="this.$route.path == '/quiz_subjects'" />
          <b-menu-item icon="gamepad-square-outline" label="Grammar Arcade" tag="router-link" to="/grammar" :active="this.$route.path == '/grammar'" />
          <b-menu-item icon="view-list" label="Event Logs" tag="router-link" to="/event_logs" :active="this.$route.path == '/event_logs'" />
          <b-menu-item icon="alert" label="Reported Content" tag="router-link" to="/reported_questions" :active="this.$route.path == '/reported_questions'" />
          <b-menu-item icon="star-circle-outline" label="Featured Quizzes" tag="router-link" to="/featured_quizzes" :active="this.$route.path == '/featured_quizzes'" />
          <b-menu-item icon="earth" label="Countries" tag="router-link" to="/countries" :active="this.$route.path == '/countries'" />
          <b-menu-item icon="beehive-outline" label="Hive Games" tag="router-link" to="/hives" :active="this.$route.path == '/hives'" />
        </b-menu-item>
        <b-menu-item
          v-if="$store.state.user && $store.state.user.dictionary_editor"
          icon="cog"
          :active="openMenu == 'system'"
          :expanded="openMenu == 'system'"
        >
          <template slot="label" slot-scope="props">
            System
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item icon="book" label="Dictionary" tag="router-link" to="/dictionary" :active="this.$route.path == '/dictionary'" />
        </b-menu-item>
        <b-menu-item
          v-if="$store.state.user && $store.state.user.superuser"
          icon="cart"
          :active="openMenu == 'merch'"
          :expanded="openMenu == 'merch'"
        >
          <template slot="label" slot-scope="props">
            Merch Store
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item
            icon="package-variant-closed"
            label="Products"
            tag="router-link"
            to="/merch-products"
            :active="this.$route.path == '/merch-products'"
          />
          <b-menu-item icon="truck" label="Merch Sales" tag="router-link" to="/merchsales" :active="this.$route.path == '/merchsales'" />
        </b-menu-item>
        <b-menu-item
          v-if="$store.state.user && $store.state.user.superuser"
          icon="currency-gbp"
          :active="openMenu == 'sales'"
          :expanded="openMenu == 'sales'"
        >
          <template slot="label" slot-scope="props">
            Sales
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item icon="receipt" label="Invoices" tag="router-link" to="/invoices" :active="this.$route.path == '/invoices'" />
          <b-menu-item
            v-if="$store.state.user.superduperuser"
            icon="cash"
            label="Finance"
            tag="router-link"
            to="/financials"
            :active="this.$route.path == '/financials'"
          />
          <b-menu-item
            v-if="$store.state.user.superuser"
            icon="calendar-blank-multiple"
            label="Subscription Plans"
            tag="router-link"
            to="/subscription-products"
            :active="this.$route.path == '/subscription-products'"
          />
          <b-menu-item
            v-if="$store.state.user.superuser"
            icon="calendar-blank-multiple"
            label="Subscription Migrations"
            tag="router-link"
            to="/subscription-actions"
            :active="this.$route.path == '/subscription-actions'"
          />
          <b-menu-item icon="poll" label="Sales Dashboard" tag="router-link" to="/sales_dashboard" :active="this.$route.path == '/sales_dashboard'" />
          <b-menu-item icon="poll" label="Cancellations" tag="router-link" to="/cancellations" :active="this.$route.path == '/cancellations'" />
          <b-menu-item icon="poll" label="Engagement Report" tag="router-link" to="/engagement_reports" :active="this.$route.path == '/engagement_reports'" />
        </b-menu-item>
        <b-menu-item icon="help" label="Help" tag="a" href="https://support.edshed.com" />
      </b-menu-list>
    </b-menu>
    <ul v-if="$store.state.user && $store.state.user.school && $store.state.user.school.teacher && $store.getters.hasSubscription" class="sidebar-menu">
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/">
          <span class="icon"><i class="mdi mdi-television" /></span>
          <span class="page">Dashboard</span>
        </router-link>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <a href="https://play.edshed.com/login"><span class="icon"><i class="mdi mdi-gamepad-variant" /></span>
          <span class="page">Web Game</span>
        </a>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/scheme">
          <span class="icon"><i class="mdi mdi-book-open" /></span>
          <span class="page">Spelling {{ $store.state.user.locale === 'en_US' ? 'Curriculum' : 'Scheme' }}</span>
        </router-link>
      </li>
      <li v-if="$store.getters.hasSpelling" class="pageLink" @click="toggleMenu">
        <router-link to="/lists">
          <span class="icon"><i class="mdi mdi-newspaper" /></span>
          <span class="page">Spelling Lists</span>
        </router-link>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <a href="http://www.mathshed.com/browse"><span class="icon"><i class="mdi mdi-calculator" /></span>
          <span class="page">{{ this.$store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Resources</span>
        </a>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <a href="http://www.literacyshed.com"><span class="icon"><i class="mdi mdi-plus-circle" /></span>
          <span class="page">Literacy Shed +</span>
        </a>
      </li>
      <!-- <li class="pageLink" @click="toggleMenu">
        <router-link to="/store">
          <span class="icon"><i class="mdi mdi-cart" /></span>
          <span class="page">Store</span>
        </router-link>
      </li> -->
      <!-- <li class="pageLink" @click="toggleMenu">
        <router-link to="/purchases">
          <span class="icon"><i class="mdi mdi-gift" /></span>
          <span class="page">Purchases</span>
        </router-link>
      </li> -->
      <li class="header">
        SCHOOL
      </li>
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/groups">
          <span class="icon"><i class="mdi mdi-grid" /></span>
          <span class="page">Your Groups</span>
        </router-link>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/homework">
          <span class="icon"><i class="mdi mdi-clock" /></span>
          <span class="page">Assignments</span>
        </router-link>
      </li>
      <li v-if="$store.getters.hasSpelling" class="pageLink" @click="toggleMenu">
        <router-link to="/hives">
          <span class="icon"><i class="mdi mdi-sitemap" /></span>
          <span class="page">Hive Games</span>
        </router-link>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/pupils">
          <span class="icon"><i class="mdi mdi-account-group" /></span>
          <span class="page">All {{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}</span>
        </router-link>
      </li>

      <!-- <li v-if="$store.state.user.school.admin" class="pageLink" @click="toggleMenu">
        <router-link to="/teachers">
          <span class="icon"><i class="mdi mdi-teach" /></span>
          <span class="page">Teachers</span>
        </router-link>
      </li>
      <li v-if="$store.state.user.school.admin" class="pageLink" @click="toggleMenu">
        <router-link to="/school">
          <span class="icon"><i class="mdi mdi-school" /></span>
          <span class="page">Manage School</span>
        </router-link>
      </li>
      <li v-if="$store.state.user.school.admin" class="pageLink" @click="toggleMenu">
        <router-link to="/manage-subscription">
          <span class="icon"><i class="mdi mdi-refresh" /></span>
          <span class="page">Manage Subscription</span>
        </router-link>
      </li> -->
      <li class="pageLink" @click="toggleMenu">
        <a href="https://support.edshed.com"><span class="icon"><i class="mdi mdi-help" /></span>
          <span class="page">Help</span>
        </a>
      </li>

    <!-- <li class="header" v-if="$store.state.user.superuser">SYSTEM</li>
    <li class="pageLink" v-on:click="toggleMenu" v-if="$store.state.user.superuser">
        <router-link to="/districts"><span class="icon"><i class="fas fa-city"></i></span>
          <span class="page">Districts</span>
        </router-link>
      </li>
    <li class="pageLink" v-on:click="toggleMenu" v-if="$store.state.user.superuser">
      <router-link to="/subscriptions"><span class="icon"><i class="far fa-building"></i></span>
        <span class="page">Schools</span>
      </router-link>
    </li>
    <li class="pageLink" v-on:click="toggleMenu" v-if="$store.state.user.superuser">
        <router-link to="/users"><span class="icon"><i class="fas fa-users"></i></span>
          <span class="page">Users</span>
        </router-link>
      </li>
    <li class="pageLink" v-on:click="toggleMenu" v-if="$store.state.user.superuser">
      <router-link to="/merchsales"><span class="icon"><i class="fas fa-shipping-fast"></i></span>
        <span class="page">Merch Sales</span>
      </router-link>
    </li>
    <li class="pageLink" v-on:click="toggleMenu" v-if="$store.state.user.superuser">
      <router-link to="/financials"><span class="icon"><i class="far fa-money-bill-alt"></i></span>
        <span class="page">Finance</span>
      </router-link>
    </li>
    <li class="pageLink" v-on:click="toggleMenu" v-if="$store.state.user.superuser">
        <router-link to="/emails"><span class="icon"><i class="far fa-envelope"></i></span>
          <span class="page">Emails</span>
        </router-link>
    </li>
    <li class="pageLink" v-on:click="toggleMenu" v-if="$store.state.user.superuser">
      <router-link to="/resources"><span class="icon"><i class="fas fa-cloud-download-alt"></i></span>
        <span class="page">Resources</span>
      </router-link>
    </li>
    <li class="pageLink" v-on:click="toggleMenu" v-if="$store.state.user.superuser">
      <router-link to="/packs"><span class="icon"><i class="fas fa-box"></i></span>
        <span class="page">Curriculum Packs</span>
      </router-link>
    </li> -->
    </ul>
    <ul v-else-if="$store.state.user" class="sidebar-menu">
      <!-- <li class="pageLink" @click="toggleMenu">
        <a href="https://play.edshed.com"><span class="icon"><i class="mdi mdi-gamepad-variant" /></span>
          <span class="page">Web Game</span>
        </a>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/scheme">
          <span class="icon"><i class="mdi mdi-book-open" /></span>
          <span class="page">Spelling {{ $store.state.user.locale === 'en_US' ? 'Curriculum' : 'Scheme' }}</span>
        </router-link>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/mathsresources">
          <span class="icon"><i class="mdi mdi-calculator" /></span>
          <span class="page">{{ this.$store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Resources</span>
        </router-link>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <a href="https://www.edshed.com/store">
          <span class="icon"><i class="mdi mdi-cart" /></span>
          <span class="page">Store</span>
        </a>
      </li> -->
      <!-- <li class="pageLink" @click="toggleMenu">
        <router-link to="/purchases">
          <span class="icon"><i class="mdi mdi-gift" /></span>
          <span class="page">Purchases</span>
        </router-link>
      </li> -->
      <li class="pageLink" @click="toggleMenu">
        <a href="https://support.edshed.com"><span class="icon"><i class="mdi mdi-help" /></span>
          <span class="page">Help</span>
        </a>
      </li>
    </ul>
    <!-- <ul v-else class="sidebar-menu">
      <li class="pageLink" @click="toggleMenu">
        <a href="https://play.edshed.com"><i class="mdi mdi-gamepad-variant" />
          <span class="page">Web Game</span>
        </a>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/scheme">
          <i class="mdi mdi-book-open" />
          <span class="page">Spelling Scheme</span>
        </router-link>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <router-link to="/mathsresources">
          <i class="mdi mdi-calculator" />
          <span class="page">{{ this.$store.state.user && this.$store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Resources</span>
        </router-link>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <a href="https://www.edshed.com/store">
          <i class="mdi mdi-cart" />
          <span class="page">Store</span>
        </a>
      </li>
      <li class="pageLink" @click="toggleMenu">
        <a href="https://support.edshed.com"><i class="mdi mdi-help" />
          <span class="page">Help</span>
        </a>
      </li>
    </ul> -->
  </div>
</template>
<script>
export default {
  name: 'SidebarName',
  computed: {
    openMenu () {
      switch (this.$route.path) {
        case '/scheme':
          return 'resources'
        case '/lists':
          return 'resources'
        case '/mathsresources':
          return 'resources'
        case '/store':
          return 'resources'
        case '/purchases':
          return 'resources'
        case '/groups':
          return 'school'
        case '/homework':
          return 'school'
        case '/hives':
          return 'school'
        case '/pupils':
          return 'school'
        case '/teachers':
          return 'settings'
        case '/school':
          return 'settings'
        case '/manage-subscription':
          return 'settings'
        case '/districts':
          return 'system'
        case '/subscriptions':
          return 'system'
        case '/users':
          return 'system'
        case '/financials':
          return 'system'
        case '/invoices':
          return 'system'
        case '/trials':
          return 'system'
        case '/merchsales':
          return 'system'
        case '/emails':
          return 'system'
        case '/resources':
          return 'system'
        case '/packs':
          return 'system'
        default:
          return ''
      }
    }
  },
  // mounted () {
  //   this.$nextTick(() => {
  //     $("body").height($(window).height() - $(".main-header").height() + "px")
  //   })
  // },
  methods: {
    toggleMenu (event) {
      // remove active from li
      // var active = document.querySelector('li.pageLink.active')

      // if (active) {
      //   active.classList.remove('active')
      // }
      // window.$('li.pageLink.active').removeClass('active')
      // Add it to the item that was clicked
      // var t = event.toElement

      // if (t && t.tagName === 'A') {
      //   event.toElement.parentElement.className = 'pageLink active'
      // } else if (t) {
      //   event.toElement.parentElement.parentElement.className = 'pageLink active'
      // }
    },
    confirmLogout () {
      const c = confirm('Logout?')
      if (c) {
        this.$router.push('/logout')
      }
    }
  }
}
</script>
<style>
  /* override default */

  .sidebar-menu>li>a {
    padding: 12px 15px 12px 15px;
  }

  .sidebar-menu li.active>a>.fa-angle-left, .sidebar-menu li.active>a>.pull-right-container>.fa-angle-left {
    animation-name: rotate;
    animation-duration: .2s;
    animation-fill-mode: forwards;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-90deg);
    }
  }

  .pageLink  {
    font-size: 18px;
  }

  .sidebar-menu>li>a {
    padding: 12px 10px 12px 10px;
    text-align: center;
  }

  .pageLink .page {
    margin-left: 10px;
  }

  /* .menu-label {
    margin-left: 5px;
  } */

  .menu {
    margin: 0 5px;
  }

  .sidebar-menu {
    display: none;
  }

  .sidebar-collapse .sidebar-menu {
    display: block;
  }

  .sidebar-collapse .menu {
    display: none;
  }

</style>
