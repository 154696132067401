<template>
  <div :class="['wrapper', classes]">
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top" role="navigation">
        <!-- Sidebar toggle button-->
        <a href="javascript:;" class="sidebar-toggle" data-toggle="offcanvas" role="button">
          <span class="sr-only">Toggle navigation</span>
        </a>
        <!-- <span class="logo-mini">
          <a href="/"><img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo" style=""></a>
        </span> -->
        <div class="navbar-custom-menu" style="margin-left:auto">
          <ul class="nav navbar-nav">
            <li v-if="$store.state.user && notifications.length > 0" class="dropdown notifications-menu" style="margin-top:0">
              <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" title="Notifications">
                <i class="far fa-bell" />
                <span v-if="notifications.length > 0" class="label label-danger">{{ notifications.length }}</span>
              </a>
              <ul class="dropdown-menu">
                <li class="header">
                  You have {{ notifications.length }} notification(s)
                </li>
                <li v-if="true">
                  <ul class="menu">
                    <li v-for="(note, ind) in notifications" :key="'note-' + ind">
                      <a href="javascript:;">
                        <i class="fa fa-user text-red" />
                      </a>
                    </li>
                  </ul>
                </li>
                <li v-if="notifications.length > 0" class="nav-footer">
                  <a href="javascript:;">View all</a>
                </li>
              </ul>
            </li>
            <b-dropdown
              position="is-bottom-left"
              append-to-body
              aria-role="menu"
              trap-focus
            >
              <template #trigger>
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <span class="icon has-text-link" style="float:left; font-size: 36px; margin-top: 20px; margin-right: 6px;"><i class="mdi mdi-chevron-down" /></span>
                  <AvatarView
                    v-if="$store.state.user"
                    style="float:left; margin-right:10px;"
                    class="user-image"
                    :uniq="'user-'+$store.state.user.id"
                    :data="($store.state.user.avatar && $store.state.user.avatar != '') ? JSON.parse($store.state.user.avatar) : {}"
                    scale-factor="0.275"
                  />
                </a>
              </template>

              <b-dropdown-item
                aria-role="menu-item"
                :focusable="false"
                @click="confirmLogout"
              >
                <a>
                  <i class="fas fa-sign-out-alt text-red" /> <span class="menu-text">Sign Out</span>
                </a>
              </b-dropdown-item>
            </b-dropdown>
          </ul>
        </div>
      </nav>
    </header>
    <!-- Left side column. contains the logo and sidebar -->
    <sidebar />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <!-- <section class="content-header">
        <h1>
          {{$route.name.toUpperCase() }}
          <small>{{ $route.meta.description }}</small>
        </h1>
        <ol class="breadcrumb">
          <li>
            <a href="/"><i class="fa fa-home"></i>Home</a>
          </li>
          <li class="active">{{$route.name.toUpperCase()}}</li>
        </ol>
      </section> -->

      <router-view :key="$route.path" />
    </div>
    <!-- /.content-wrapper -->

    <!-- Main Footer -->
    <footer class="footer main-footer text-right">
      <p class="is-pulled-right">
        Copyright &copy; {{ year }}
        <a href="http://www.edshed.com">Education Shed Ltd.</a> All rights reserved.
      </p>
    </footer>
  </div>
  <!-- ./wrapper -->
</template>

<script>
import { mapState } from 'vuex'
import { request } from '@/edshed-common/api'
import AvatarView from '@/components/views/AvatarView'
import config from '../config'
import Sidebar from './Sidebar'
import 'hideseek'

export default {
  name: 'Dash',
  components: {
    Sidebar,
    AvatarView
  },
  data () {
    return {
      // section: 'Dash',
      year: new Date().getFullYear(),
      classes: {
        fixed_layout: config.fixedLayout,
        hide_logo: config.hideLogoOnMobile
      },
      error: '',
      notifications: []
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ]),
    activeUser () {
      return {
        displayName: this.$store.state.user.name,
        schoolName: this.$store.state.user.school.school_name,
        username: this.$store.state.user.username
      }
    },
    loginUrl () {
      const base = config.authURI
      const ret = window.location.origin

      return base + 'accesstoken?return_url=' + encodeURIComponent(ret)
    }
  },
  methods: {
    showSwitchSchool () {
      request('POST', 'users/me/detachschool', null, this.$store.state.tokan)
        .then((response) => {
          const data = response.data
          console.log(data)
          if (data.user) {
            const token = data.user.token
            this.$store.commit('SET_USER', data.user)
            this.$store.commit('SET_TOKEN', token)

            // console.log('token: ' + token)
            // console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
              window.localStorage.setItem('token', token)
            }

            this.$router.push('/schools')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },

    changeloading () {
      this.$store.commit('TOGGLE_SEARCHING')
    },
    confirmLogout () {
      const c = confirm('Logout?')
      if (c) {
        this.$router.push('/logout')
        // window.location.replace('https://www.edshed.com/logout')
      }
    }
  }
}
</script>

<style lang="scss">

@font-face {
  font-family: 'OpenDyslexicAltaRegular';
  src: url('/public/fonts/opendyslexicalta-regular-webfont.woff2') format('woff2'),
       url('/public/fonts/opendyslexicalta-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cookies';
  src: url('/public/fonts/cookies-webfont.woff2') format('woff2'),
       url('/public/fonts/cookies-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Muli');

body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Muli';
}

.content-wrapper {
  background-color: #e4f3f6;
  background-color: #dfecef;
  background-color: #f8f8f8;
}

.right-side {
  background-color: #282b30;
}

.wrapper {
  display: flex;
  flex-direction: column;
  // .content-wrapper {
  //   /* z-index: 1050 !important; */
  // }
  // .main-header, .main-footer {
  //   /* z-index: 1041 !important; */
  // }
}

.wrapper.fixed_layout {
  .main-header {
    position: fixed;
    width: 100%;
  }

  .content-wrapper {
    padding-top: 50px;
    /* z-index: 1050 !important; */
  }

  .main-sidebar {
    position: fixed;
    height: 100vh;
  }
}

.wrapper.hide_logo {
  @media (max-width: 767px) {
    .main-header .logo {
      display: none;
    }
  }
}

.logo-mini,
.logo-lg {
  text-align: left;
  margin: 0 auto;

  img {
    padding: .4em !important;
  }
}

.logo-lg {
  img {
    display: -webkit-inline-box;
    width: 25%;
  }
}

.user-panel {
  height: 4em;
}

hr.visible-xs-block {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  height: 1px;
  border-color: transparent;
}
.dropdown-menu>li>a {
  padding: 8px 20px;
}

.skin-blue .main-header .logo, .skin-blue .main-header .logo:hover  {
  float:none;
  background:transparent;
}

.skin-blue .main-header .navbar {
  background-color: #6ac8dd;
}

footer.main-footer {
    background-color: white;
    padding: 15px;
    font-family: 'Muli';
    font-size: 0.8rem;
    font-weight:100;
}

@media (max-width: 767px) {

  .main-header .logo {
    width: inherit;
  }
}

@media only print {
  #social {
    display: none;
  }
}

/* Reset z-index */
.sidebar-mini.sidebar-collapse .main-sidebar {
  z-index: initial;
}

.sidebar-mini.sidebar-collapse .content-wrapper {
  z-index: initial;
}

.main-header {
  z-index: initial;
}

.content-wrapper, .right-side {
  z-index: initial;
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right) {
  width: initial !important;
  left: 0;
  padding:0;
  margin:0;
}

.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right):not(.icon), .sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
  display: none !important;
}

.btn-box-tool {
  color: #4a4a4a;
}

.dropdown-menu .item a {
  font-size: 12px;
}

.menu-text {
  margin-left: 10px;
}

.button.is-warning {
    background-color: #ffdd57 !important;
    border-color: transparent !important;
    color: rgba(0, 0, 0, 0.7) !important;
}
</style>
