<template>
  <aside class="main-sidebar">
    <a href="https://www.edshed.com/" class="logo-mini logo-maxi">
      <img src="/img/logo-edshed-white.png" alt="Logo" class="img-responsive" style="margin-left: 7px">
    </a>
    <a href="https://www.edshed.com/" class="logo-mini logo-min">
      <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block">
    </a>
    <p v-if="$store.state.user && $store.state.user.school" class="schoolName">
      {{ $store.state.user.school.school_name }} <small><i>(#{{ $store.state.user.school.id }})</i></small>
    </p>
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
      <!-- Sidebar Menu -->
      <sidebar-menu />
      <!-- /.sidebar-menu -->
    </section>
    <!-- /.sidebar -->
  </aside>
</template>
<script>
import SidebarMenu from './SidebarMenu'

export default {
  name: 'Sidebar',
  components: { SidebarMenu },
  data () {
    return {
      schoolName: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      // if (this.$store.state.user && this.$store.state.user.school) {
      //   this.schoolName =   this.$store.state.user.school.school_name
      // }
    })
  }
}
</script>
<style>
  .user-panel .image img {
    border-radius: 50%;
  }

  aside.main-sidebar {
    /* position: fixed; */
    padding-top:0 !important;
  }

  .main-sidebar .logo-mini img {
    height: 50px;;
  }

  .schoolName {
    color: #80899a;
    color: #fff;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 13px;
  }
</style>
